<template>
    <v-container fluid>
        <v-layout wrap>
            <v-flex>
                <v-card :loading="loading" outlined style="border: none;">
                    <v-card-title class="px-1 d-flex align-center justify-center">
                        <p class="blod_color">
                            Lançamento de Evolução
                        </p>
                    </v-card-title>
                    <v-card class="px-1">
                        <v-form ref="form"></v-form>
                        <!-- <pre>{{ evolucoes }}</pre> -->
                        <v-row cols="12" sm="6" dense >
                            <!-- Escolha do Paciente -->
                            <v-col cols="12" sm="6" >
                                <v-autocomplete 
                                    label="Paciente"
                                    outlined 
                                    dense 
                                    deletable-chips
                                    chips
                                    readonly
                                    small-chips 
                                    required
                                    v-model="paciente" 
                                    :items="pacientes"
                                    return-object 
                                    item-value="id" 
                                    item-text="paciente.nome"
                                    @change="get_pacientes()"
                                ></v-autocomplete>
                            </v-col>
                            <!-- Data de Nascimnto -->
                            <v-col cols="12" sm="6">
                                <v-text-field 
                                    label="Data de Nascimento" v-mask="'##/##/####'"
                                    outlined 
                                    dense 
                                    readonly 
                                    v-model="evolucao.data_nascimento"
                                ></v-text-field>
                            </v-col>
                            <!-- Escolha do Procedimento -->
                            <v-col cols="12" sm="6">
                                <v-autocomplete 
                                    label="Procedimento" 
                                    outlined 
                                    dense
                                    deletable-chips 
                                    chips 
                                    readonly
                                    small-chips 
                                    required
                                    :items="evolucoes"
                                    item-value="id" 
                                    item-text="evolucao"
                                    @change="get_evolucoes()"
                                ></v-autocomplete>
                            </v-col>
                            <!-- Data da Evolução -->
                            <v-col cols="12" sm="6">
                                <v-text-field 
                                    label="Data da Evolução" 
                                    outlined 
                                    dense
                                    required 
                                    type="date" 
                                    v-model="evolucao.data" 
                                    :rules="[requiredField, dataValida]"
                                    :error-messages="dataErrorMessage"
                                ></v-text-field>
                            </v-col>
                            <!-- Hora da Evolução Início e Fim -->
                            <v-col cols="12" sm="6">
                                <v-row>
                                    <v-text-field
                                    label="Hora de Início" 
                                    class="px-6" 
                                    outlined 
                                    dense
                                    required
                                    type="time"
                                    v-model="evolucao.hora_inicio"
                                    :rules="[requiredField, horaInicioMenorQueHoraFim]"
                                    @change="revalidate('horaFimField')"
                                    ref="horaInicioField" 
                                    ></v-text-field>
                                    <v-text-field
                                    label="Hora de Término" 
                                    class="px-6" 
                                    outlined 
                                    dense
                                    required
                                    type="time"
                                    v-model="evolucao.hora_fim"
                                    :rules="[requiredField, horaInicioMenorQueHoraFim]"
                                    @change="revalidate('horaInicioField')"
                                    ref="horaFimField" 
                                    ></v-text-field>
                                </v-row>
                            </v-col>
                            <!-- Texto da Evolução -->
                            <v-col cols="12" sm="6">
                                <v-textarea 
                                    label="Evolução" 
                                    conter 
                                    placeholder="O texto deve ter no mínimo 50 caracteres"
                                    auto-grow 
                                    outlined 
                                    required
                                    rows="4" 
                                    row-height="30"
                                    v-model="txt_evolucao" 
                                    :rules="rulesEvolucao" 
                                ></v-textarea>
                            </v-col>
                            <!-- Texto da Meta -->
                            <v-col cols="12" sm="6">
                                <v-textarea 
                                    label="Meta" 
                                    conter 
                                    placeholder="O texto deve ter no mínimo 30 caracteres"
                                    outline
                                    required
                                    auto-grow
                                    outlined 
                                    rows="4" 
                                    row-height="15"
                                    v-model="txt_meta" 
                                    :rules="rulesMeta" 
                                ></v-textarea>
                            </v-col>
                            <!-- Texto da Informaçõa para Prorrogação -->
                            <v-col cols="12" sm="6">
                                <v-textarea 
                                    label="Informação para Prorrogação" 
                                    conter 
                                    placeholder="O texto deve ter no mínimo 30 caracteres"
                                    auto-grow 
                                    outlined 
                                    required
                                    rows="4" 
                                    row-height="15"
                                    v-model="txt_inf_prorrogacao" 
                                    :rules="rulesInfProrrogacao" 
                                ></v-textarea>
                            </v-col>
                            <!-- Anexar Arquivos -->
                            <v-col cols="12" sm="6">
                                <v-file-input 
                                    label="Anexar Arquivos ou Imagens" 
                                    outlined 
                                    dense
                                    deletable-chips 
                                    small-chips 
                                    multiple
                                    prepend-icon="mdi-paperclip"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip small label color="primary">
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                            <!-- Botões de Assintaura, Salvar e Limpar -->
                            <v-col>
                                <v-row justify="space-around" style="padding-bottom: 5%;" dense>
                                    <v-btn 
                                        color="#306AA0" 
                                        rounded 
                                        dark 
                                        style="font-weight: bold;"
                                    >
                                        Salvar
                                    </v-btn>
                                    <v-btn 
                                        color="red" 
                                        rounded 
                                        dark
                                        style="font-weight: bold;"
                                        @click="limpar()"
                                   >
                                        Limpar
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import api from '../../../../http';
import '../../../../assets/css/styles.css';

export default {
    name: "evolucao_equipe_multi",
    created() {
        window.scroll(0,0);
    },
    data: () => ({
        dataErrorMessage: '',
        pacientes: [],
        txt_evolucao: [],
        txt_meta:[],
        txt_inf_prorrogacao:[],
        paciente: {},
        evolucoes: [],
        evolucao: {},
        loading:false,
        rulesEvolucao: [
            (v) => v.length >= 50 || "O texto deve ter no mínimo 50 caracteres",
        ],
        rulesMeta: [
            (v) => v.length >= 30 || "O texto deve ter no mínimo 30 caracteres",
        ],
        rulesInfProrrogacao: [
        (v) => v.length >= 30 || "O texto deve ter no mínimo 30 caracteres",
        ],
    }),
    methods: {
        get_pacientes() {
            this.loading = true;
            api('atendimentos/sessoes/')
                .then(res => {
                    this.pacientes = res.data
                })
            this.loading = false;
        },
        get_evolucoes() {
            this.loading = true;
            api(`atendimentos/evolucoes/?sessao=${this.paciente.id}&status=afazer`)
                .then(res => {
                    this.evolucoes = res.data
                })
            this.loading = false;
        },
        // usado para dividir hora e minuto para o horaInicioMenorQueHoraFim analise.
        convert(val){
            if(val !== undefined){
                const hour = val.split(':')[0];
                const minute = val.split(':')[1];
                return parseInt(hour) * 60 + parseInt(minute);
            }
            return 24 * 60; // equivalente a 24:00
        },
        limpar(){
            this.txt_evolucao =[]
            this.txt_meta=[]
            this.txt_inf_prorrogacao=[]
            this.evolucao={}
            this.evolucoes=[]
            this.paciente={}
            this.evolucao.data=[]
            this.evolucao_hora_inicial=null
            this.evolucao_hora_fim=null
        },
        // preencher a hora inicial e hora final 
        formatTime(date) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            return hours + ':' + minutes;
        },
        // msg quando for limpo o campo de hora inicial e hora final
        requiredField(value) {
            return !!value || 'Campo obrigatório.';
        },
        // comprando a hora inicial com a hora final para valirdar 
        horaInicioMenorQueHoraFim() {
            if (this.evolucao.hora_inicio && this.evolucao.hora_fim) {
            const inicio = this.convert(this.evolucao.hora_inicio);
            const fim = this.convert(this.evolucao.hora_fim);
            return inicio < fim || 'Hora de Término não pode ser MENOR que Hora de Início.';
            }
            return true;
        },
        // Reavaliar a hora inical da hora final 
        revalidate(refName) {
            this.$nextTick(() => {
            if (this.$refs[refName]) {
                this.$refs[refName].validate();
            }
            });
        },
    },
    computed:{
        // analisando a data escolhida para evolucao, tem que ser entre o dia atual e o início do mês
        dataValida() {
            const hoje = new Date();
            const primeiroDiaMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
            return (valor) => {
                if (valor < primeiroDiaMes.toISOString().slice(0, 10)) {
                    this.dataErrorMessage = 'A Data da Evolução não pode ser anterior ao primeiro dia do mês atual.';
                    return false;
                } else if (valor > hoje.toISOString().slice(0, 10)) {
                    this.dataErrorMessage = 'A Data da Evolução não pode ser posterior à data atual.';
                    return false;
                }
                this.dataErrorMessage = '';
                return true;
            };
        },
        
    },
    mounted() {
        // iniciar a pagina com a data atual
        this.get_pacientes();
        const dataAtual = new Date();
        this.evolucao.data = dataAtual.toISOString().slice(0, 10);
        
        //Iniciar a pagina com a hora final a do momento que a pagina é montada
        const horaFim = new Date();
        this.evolucao.hora_fim = this.formatTime(horaFim);

        // Iniciar a pagina com a hora inicial menos 30 minutos que a hora final automaticamente
        const horaInicio = new Date(horaFim - 30 * 60 * 1000); // 30 minutos antes
        this.evolucao.hora_inicio = this.formatTime(horaInicio);
    },
    // campo watch usado para caso o usuario mude primeiro a hora incial e depois a hora final para que reavalie, analise novamente 
    watch: {
        'evolucao.hora_inicio': function() {
            this.$nextTick(() => {
                this.$refs.horaInicioField.validate();
                this.$refs.horaFimField.validate();
            });
        },
        'evolucao.hora_fim': function() {
            this.$nextTick(() => {
                this.$refs.horaInicioField.validate();
                this.$refs.horaFimField.validate();
            });
        }
    },
}
</script>